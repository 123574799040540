import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 392 369" fill="none" {...props}>
    <path d="M39.0082 215.005C36.4756 218.718 34.2596 222.31 32.4211 225.719C7.59456 271.683 25.5904 329.543 72.6256 354.954C119.661 380.365 177.922 363.708 202.749 317.744C204.587 314.335 206.377 310.512 208.094 306.36L39.0082 215.005Z" fill="white" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M208.991 306.144C212.347 299.932 176.939 274.297 129.905 248.888C82.8708 223.478 42.0216 207.916 38.6656 214.128C35.3097 220.34 70.7178 245.974 117.752 271.384C164.786 296.793 205.635 312.356 208.991 306.144Z" fill="#0BDB90"/>
    <path d="M89.9792 112.045L268.66 182.798C283.879 128.689 281.822 23.0028 235.797 4.7756C189.772 -13.4516 115.938 62.1846 89.9792 112.045Z" fill="white" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M269.229 184.982C271.846 178.373 233.418 156.958 183.399 137.15C133.379 117.342 90.7083 106.642 88.0912 113.251C85.4741 119.86 123.902 141.275 173.921 161.083C223.941 180.891 266.611 191.59 269.229 184.982Z" fill="#0BDB90"/>
    <path d="M193.799 303.96L167.366 288.741L196.242 260.499C211.357 245.717 221.398 226.525 224.923 205.68L231.981 163.938L263.555 179.919L227.75 223.985C220.349 233.093 214.609 243.434 210.793 254.533L193.799 303.96Z" fill="#0BDB90"/>
    <path d="M388.239 185.125C355.586 160.029 271.842 130.915 198.089 215.221" stroke="#003AFE" strokeWidth="12"/>
    <path d="M199.023 213.859C189.743 225.295 174.314 231.663 160.288 228.136C160.288 228.136 169.629 261.356 149.65 265.867C129.672 270.379 127.371 229.855 132.177 207.723C135.972 185.569 153.77 145.575 171.809 151.054C192.648 157.382 167.75 188.952 167.75 188.952C167.75 188.952 150.948 214.853 100.399 215.004C53.5217 202.244 -27.9346 154.319 21.2558 64.7048" stroke="#003AFE" strokeWidth="12" strokeMiterlimit="10"/>
    <path d="M87.6001 236.994L67.5239 225.083L84.5997 207.875C99.2471 193.113 108.928 174.153 112.296 153.632L116.599 127.412L140.579 139.918L115.698 171.472C108.545 180.543 103.018 190.784 99.3625 201.741L87.6001 236.994Z" fill="#0BDB90"/>
    <path d="M163.104 278.392C193.544 241.864 213.025 189.508 216.678 167.592L228.245 174.288C218.017 179.646 176.498 265.607 174.062 284.48L163.104 278.392Z" fill="#0BDB90"/>
    <path d="M202.675 156.634L189.282 197.423L212.416 161.504L202.675 156.634Z" fill="#0BDB90"/>
    <circle cx="191.108" cy="193.769" r="4.26154" fill="#0BDB90"/>
    <path d="M48.3574 218.232L61.1103 194.511L42.826 214.161L48.3574 218.232Z" fill="#0BDB90"/>
    <ellipse cx="59.6717" cy="197.748" rx="5.20306" ry="5.20306" transform="rotate(-170.043 59.6717 197.748)" fill="#0BDB90"/>
  </Svg>
  
  
);

export default Icon;
