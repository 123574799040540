/**
 * Create by Krystal
 * Description: index
 * Date: 2023/1
 * Update: 2023/1
 */

import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Hidden from "../../components/Hidden";
import { BackDropIcon, BlueTriangleIcon, BtcCoinIcon, CoinOneIcon, CoinThreeIcon, CoinTwoIcon, EggIcon, EthCoinIcon, GreenTriangleIcon, HandEnvelopeIcon, HandUpIcon, IconYellowIcon, LeftPushHandIcon, StarIcon, YellowIcon } from "../../components/Svg";
import useSystem from "../../hooks/useSystem";

import "./index.css"

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center
`

const HomeContent = styled.div`
  max-width: 1240px;
  padding: 92px 16px 0;
  margin: auto;
  box-sizing: border-box;
`
const ItemContent = styled(Flex)`
  justify-content: space-between;
`
const ItemTwoContent = styled(Flex)`
  margin-top: 94px;
  position: relative;
`

const ItemThreeContent = styled(Flex)`
  // margin-top: 94px;
  position: relative;
`

const ItemTwoContainer = styled(Flex)`
  padding-top: 88px;
  padding-bottom: 280px;
  position: relative;
  width: 100%;
`

const BgContainer = styled.div`
  width: 200%;
  height: 580px;
  background-color: #F6F9FF;
  position: absolute;
  left: -50%;
  top: 94px;
  transform: rotate(10deg);
  @media (min-width: 852px) and (max-width: 1100px) {
    height: 452px;
  }
  @media (max-width: 852px) {
    height: 252px;
    color: #003AFE;
    font-size: 24px;
    top: 44px;
  }
`

const Left = styled.div`
  // width: 610px;
  padding-top: 58px;
  text-align: left;
  position: relative;
`
const Right = styled.div`
  position: relative;
  text-align: right;
`
const ItemTwoRight = styled(Right)`
  padding-top: 68px;
  display: flex;
  flex-direction: column;
  align-items: end;
`
const Title = styled.div`
  overflow: hidden;
  p{
    -webkit-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    transform-style: preserve-3d;
    font-family: 'Outfit-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 120%;
    color: #050E2D;
  }
  @media (min-width: 852px) and (max-width: 1100px) {
    p {
      font-size: 28px;
    }
  }
  @media (max-width: 852px) {
    p {
      color: #003AFE;
      font-size: 24px;
    }
  }
`
const SubTitle = styled.div`
  // width: 506px;
  p {
    -webkit-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)
    font-family: 'Outfit-Medium';
    font-style: normal;
    font-size: 32px;
    line-height: 120%;
    color: #050E2D;
    text-align: left;
    &.incubate__p {
      text-indent: 10px;
    }
  }
  @media (min-width: 852px) and (max-width: 1100px) {
    p {
      font-size: 22px;
      &.incubate__p {
        text-indent: 0px;
      }
    }
  }
  @media (max-width: 852px) {
    p {
      color: #003AFE;
      font-size: 16px;
      &.incubate__p {
        text-indent: 0px;
      }
    }
  }
`

const Text = styled.div`
  width: 460px;
  margin-top: 44px;
  overflow: hidden;
  p {
    -webkit-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)
    font-family: 'Outfit-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #050E2D;
  }
  @media (min-width: 852px) and (max-width: 1100px) {
    width: 360px;
    p {
      font-size: 12px;
    }
  }
  @media (max-width: 852px) {
    width: 100%;
    margin-top: 24px;
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
    }
  }
`
const ItemTwoText = styled(Text)`
  width: 506px;
  padding-top: 42px;
  text-align: left;
  overflow: hidden;
  @media (min-width: 852px) and (max-width: 1100px) {
    width: 300px;
  }
`
const ItemThreeText = styled(Text)`
  width: 520px;
  overflow: hidden;
  @media (min-width: 852px) and (max-width: 1100px) {
    width: 320px;
  }
  @media (max-width: 852px) {
    width: 100%;
  }
`

const LeftPushHand = styled(LeftPushHandIcon)`
  position: absolute;
  bottom: -2px;
  right: 28px;
  @media (max-width: 852px) {
    bottom: 0px;
    right: -32px;
  }
`
const BtcCoin = styled(BtcCoinIcon)`
  position: absolute;
  top: 94px;
  right: 196px;
  @media (max-width: 852px) {
    top: 94px;
    right: 88px;
  }
`
const EthCoin = styled(EthCoinIcon)`
  position: absolute;
  bottom: 116px;
  left: 186px;
  @media (max-width: 852px) {
    bottom: 58px;
    left: 92px;
  }
`
const CoinOne = styled(CoinOneIcon)`
  position: absolute;
  top: -20px;
  right: 90px;
  transform: scale(0);
  @media (max-width: 852px) {
    top: 34px;
    right: 46px;
  }
`
const ItemTwoCoinOne = styled(CoinOneIcon)`
  position: absolute;
  top: 80px;
  right: 0px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  @media (max-width: 852px) {
    top: 58px;
    right: 52px;
  }
`
const CoinOneTwo = styled(CoinOneIcon)`
  position: absolute;
  bottom: 110px;
  left: 66px;
  // -webkit-transform: rotate(90deg);
  // -ms-transform: rotate(90deg);
  // transform: scale(0);
  @media (max-width: 852px) {
    bottom: 56px;
    left: 32px;
  }
`
const ItemTwoCoinOneTwo = styled(CoinOneIcon)`
  position: absolute;
  bottom: 0;
  left: 94px;
  @media (max-width: 852px) {
    bottom: 20px;
    left: 22px;
  }
`
const CoinThreeTwo = styled(CoinThreeIcon)`
  position: absolute;
  top: 0px;
  left: 80px;
  // transform: scale(0);
  @media (max-width: 852px) {
    top: 50px;
    left: 18px;
  }
`
const ItemTwoCoinThreeTwo = styled(CoinThreeIcon)`
  position: absolute;
  top: 120px;
  left: 30px;
  // transform: rotate(178deg);
  @media (max-width: 852px) {
    top: 94px;
    left: 0px;
    right: unset;
  }
`
const CoinThree = styled(CoinThreeIcon)`
  position: absolute;
  bottom: 120px;
  right: 58px;
  transform: scale(0);
  @media (max-width: 852px) {
    bottom: 50px;
    right: 30px;
  }
`
const ItemTwoCoinThree = styled(CoinThreeIcon)`
  position: absolute;
  bottom: 66px;
  right: -40px;
  transform: scale(0);
  @media (max-width: 852px) {
    top: 112px;
    right: 40px;
  }
`
const Egg = styled(EggIcon)`
  margin-left: 148px;
  @media (max-width: 852px) {
    margin: auto;
    position: relative;
    top: -12px;
  }
`

const HandUp = styled(HandUpIcon)`
  position: absolute;
  bottom: -50px;
  right: 80px;
  @media (max-width: 852px) {
    bottom: 0;
    right: 112px;
  }
`
const IconYellow = styled(IconYellowIcon)`
  position: absolute;
  bottom: 86px;
  right: 152px;
  z-index: -1;
  @media (max-width: 852px) {
    top: 25px;
    left: 28px;
    z-index: 1;
  }
`
const BlueTriangle = styled(BlueTriangleIcon)`
  position: absolute;
  bottom: 104px;
  left: -52px;
  z-index: 10;
  @media (max-width: 852px) {
    top: 110px;
    left: 46px;
  }
`
const GreenTriangle = styled(GreenTriangleIcon)`
  position: absolute;
  top: 19px;
  right: 100px;
  z-index: 10;
  transform: rotate(273deg);
  @media (max-width: 852px) {
    right: 120px;
    top: 58px;;
  }
`
const GreenTriangleTwo = styled(GreenTriangleIcon)`
  position: absolute;
  bottom: 20px;
  right: 0px;
  z-index: 10;
  @media (max-width: 852px) {
    bottom: 0;
    right: 58px;
    top: unset;
  }
`
const Star = styled(StarIcon)`
  position: absolute;
  bottom: -20px;
  left: -70px;
  z-index: 10;
  @media (max-width: 852px) {
    top: unst;
    right: unset;
    bottom: -12px;
    left: 56px;
  }
`
const HandEnvelope = styled(HandEnvelopeIcon)`
  margin-right: 80px;
  z-index: 10;
  @media (max-width: 852px) {
    margin-right: unset;
    position: inherit;
  }
`
const HomeApp = styled.div`
  padding: 0 14px;
  box-sizing: border-box;
  box-shadow: 4px 4px 24px rgba(210, 212, 217, 0.4);
  border-radius: 20px;
`
const HomeAppContent = styled.div`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 4px 4px 24px rgba(210, 212, 217, 0.4);
  border-radius: 20px;
`
const AppItem = styled.div`
  padding: 0 24px 24px;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
`
const AppImage = styled.div`
  position: relative;
  padding-top: 50px;
  width: 280px;
  margin: auto;
`
const AppText = styled.div`
  margin-top: 56px;
  text-align:left;
  z-index: 10;
  position: inherit;
`

const Home = () => {
  // const titleRef = useRef()
  const [isAnimate, setIsAnimate] = useState(false)
  const [isTwoAnimate, setIsTwoAnimate] = useState(false)
  const [isThreeAnimate, setIsThreeAnimate] = useState(false)
  // window.onload = function() {
  //   setIsAnimate(true)
  // }
  useEffect(() =>{
    setIsAnimate(true)
  }, [])

  const scrollChange = () => {
    // scrollTop
    if (document.documentElement.clientWidth >= 825 && document.documentElement.scrollTop > 300) {
      setIsTwoAnimate(true)
    } else if (document.documentElement.clientWidth < 825 && document.documentElement.scrollTop > 60) {
      setIsTwoAnimate(true)
    } else {
      setIsTwoAnimate(false)
    }
    if (document.documentElement.clientWidth >= 825 && document.documentElement.scrollTop > 810) {
      setIsThreeAnimate(true)
    } else if (document.documentElement.clientWidth < 825 && document.documentElement.scrollTop > 364) {
      setIsThreeAnimate(true)
    } else {
      setIsThreeAnimate(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollChange,true)
    scrollChange()
    return () => {
      window.removeEventListener('scroll', scrollChange,false)
    }
  }, [])

  return (
    <>
      <Hidden middleDown>
        <HomeContent>
          <ItemContent>
            <Left>
              <Title className={isAnimate ? 'animate__title' : ''}><p>Pushing The Frontiers Of</p></Title>
              <Title className={isAnimate ? 'animate__title' : ''}><p>Blockchain Technology To</p></Title>
              <Title className={isAnimate ? 'animate__title' : ''}><p>Benefit 1 Billion People</p></Title>
              <Text className={isAnimate ? 'animate__text' : ''}>
                <p>Frontier Labs is a hub of global blockchain builders. In Frontier Labs, blockchain builders share their insights and communicate with the top-notch masterminds. Together we will make blockchain technology benefit all mankind.</p>
              </Text>
            </Left>
            <Right>
              <BackDropIcon width={600} />
              <LeftPushHand className={isAnimate ? 'animate__hand' : ''} width={180} />
              <BtcCoin className={isAnimate ? 'animate_btc' : ''} width={110} />
              <EthCoin className={isAnimate ? 'animate_eth' : ''} width={110} />
              <CoinOne className={isAnimate ? 'animate_coin-one' : ''} width={60} />
              <CoinOneTwo className={isAnimate ? 'animate_coin-one-two' : ''} width={60} />
              <CoinThree className={isAnimate ? 'animate_coin-one' : ''} width={60} />
              <CoinThreeTwo className={isAnimate ? 'animate_coin-three-two' : ''} width={60} />
            </Right>
          </ItemContent>
          <ItemTwoContent id="incubate">
            <BgContainer />
            <ItemTwoContainer>
              <Left>
                <Egg className={isTwoAnimate ? 'animate_egg' : ''} width={280} />
                <HandUp width={190} />
                <ItemTwoCoinOne className={isTwoAnimate ? 'animate_coin-one' : ''} width={60} />
                <ItemTwoCoinOneTwo className={isTwoAnimate ? 'animate_coin-one-two' : ''} width={60} />
                <ItemTwoCoinThree className={isTwoAnimate ? 'animate_coin-one' : ''} width={80} />
                <ItemTwoCoinThreeTwo className={isTwoAnimate ? 'animate_coin-three-two' : ''} width={60} />
              </Left>
              <ItemTwoRight>
                <Title className={isTwoAnimate ? 'animate__title' : ''}>
                  <p>What is Frontier Incubate?</p>
                </Title>
                <SubTitle style={{marginTop: '24px'}} className={isTwoAnimate ? 'animate__title' : ''}>
                  <p>Frontier Incubate - For the builders,</p>
                </SubTitle>
                <SubTitle className={isTwoAnimate ? 'animate__title' : ''} style={{width: '100%'}}>
                  <p className="incubate__p">by the builders.</p>
                </SubTitle>
                <ItemTwoText className={isTwoAnimate ? 'animate__text' : ''}>
                  <p>Frontier Incubate is the place where the innovators and builders gather around, communicate their insights and create the real masterpiece utiliziing blockchain technology. In Frontier Incubate, the most creative innovations will be able to receive the one-stop service (fund-raising, community building etc)to kickoff their projects.</p>
                </ItemTwoText>
              </ItemTwoRight>
            </ItemTwoContainer>
          </ItemTwoContent>
          <ItemThreeContent>
            <Left>
              <Title className={isThreeAnimate ? 'animate__title' : ''}>
                <p>How to Apply</p>
              </Title>
              <SubTitle style={{marginTop: '2px'}} className={isThreeAnimate ? 'animate__title' : ''}>
                <p>Moon your project with the</p>
              </SubTitle>
              <SubTitle className={isThreeAnimate ? 'animate__title' : ''}><p>top-tier team</p></SubTitle>
              <ItemThreeText className={isThreeAnimate ? 'animate__text' : ''}>
                <p>As long as you want to build your project with top minds, you can apply via the email below. Frontier Incubate will guide your through from product designing to marketing. We have a large user base which can provide an unique access to your target users.</p>
              </ItemThreeText>
              <ItemThreeText className={isThreeAnimate ? 'animate__text' : ''}>
                <p>Email your application to contact@frontierlabs.tech with your innovations inside, your application will be proceeded faster.</p>
              </ItemThreeText>
            </Left>
            <Right>
              <HandEnvelope className={isThreeAnimate ? 'animate_handup' : ''} width={320} />
              <BlueTriangle className={isThreeAnimate ? 'animate_common': ''} width={34} />
              <GreenTriangle className={isThreeAnimate ? 'animate_common': ''} width={38} />
              <GreenTriangleTwo className={isThreeAnimate ? 'animate_common': ''} width={38} />
              <Star className={isThreeAnimate ? 'animate_common': ''} width={48} />
              <IconYellow className={isThreeAnimate ? 'animate_yellow' : ''} width={250} />
            </Right>
          </ItemThreeContent>
        </HomeContent>
      </Hidden>
      {/* h5 */}
      <Hidden smallUp>
        <HomeApp>
          <HomeAppContent>
            <AppItem>
              <AppImage>
                <BackDropIcon width={280} />
                <LeftPushHand className={isAnimate ? 'animate__hand' : ''} width={80} />
                <BtcCoin className={isAnimate ? 'animate_btc' : ''} width={50} />
                <EthCoin className={isAnimate ? 'animate_eth' : ''} width={50} />
                <CoinOne className={isAnimate ? 'animate_coin-one' : ''} width={30} />
                <CoinOneTwo className={isAnimate ? 'animate_coin-one-two' : ''} width={30} />
                <CoinThree className={isAnimate ? 'animate_coin-one' : ''} width={30} />
                <CoinThreeTwo className={isAnimate ? 'animate_coin-three-two' : ''} width={30} />
              </AppImage>
              <AppText>
                <Title className={isAnimate ? 'animate__title' : ''}>
                  <p>Pushing The Frontiers Of Blockchain Technology To Benefit 1 Billion People</p>
                </Title>
                <Text className={isAnimate ? 'animate__text' : ''}>
                  <p>Frontier Labs is a hub of global blockchain builders. In Frontier Labs, blockchain builders share their insights and communicate with the top-notch masterminds. Together we will make blockchain technology benefit all mankind.</p>
                </Text>
              </AppText>
            </AppItem>
            <AppItem>
              <BgContainer />
              <AppImage>
                <Egg className={isAnimate ? 'animate_egg' : ''} width={180} />
                <HandUp width={100} />
                <ItemTwoCoinOne className={isAnimate ? 'animate_coin-one' : ''}  width={30} />
                <ItemTwoCoinOneTwo className={isAnimate ? 'animate_coin-one-two' : ''}  width={30} />
                <ItemTwoCoinThree className={isAnimate ? 'animate_coin-one' : ''}  width={40} />
                <ItemTwoCoinThreeTwo className={isAnimate ? 'animate_coin-three-two' : ''}  width={40} />
              </AppImage>
              <AppText>
                <Title className={isTwoAnimate ? 'animate__title' : ''}>
                  <p>What is Frontier Incubate?</p>
                </Title>
                <SubTitle style={{marginTop: '15px'}} className={isTwoAnimate ? 'animate__title' : ''}>
                  <p>Frontier Incubate - For the builders,</p>
                </SubTitle>
                <SubTitle className={isTwoAnimate ? 'animate__title' : ''}>
                  <p>by the builders.</p>
                </SubTitle>
                <Text className={isTwoAnimate ? 'animate__text' : ''}>
                  <p>Frontier Incubate is the place where the innovators and builders gather around, communicate their insights and create the real masterpiece utiliziing blockchain technology. In Frontier Incubate, the most creative innovations will be able to receive the one-stop service (fund-raising, community building etc)to kickoff their projects.</p>
                </Text>
              </AppText>
            </AppItem>
            <AppItem>
              <AppImage style={{marginTop: '50px'}}>
                <IconYellow className={isThreeAnimate ? 'animate_yellow' : ''} width={120} />
                <HandEnvelope className={isThreeAnimate ? 'animate_handup': ''} width={120} />
                <BlueTriangle className={isThreeAnimate ? 'animate_common': ''} width={14} />
                <GreenTriangle className={isThreeAnimate ? 'animate_common': ''} width={14} />
                <GreenTriangleTwo className={isThreeAnimate ? 'animate_common': ''} width={14} />
                <Star className={isThreeAnimate ? 'animate_common': ''} width={24} />
              </AppImage>
              <AppText>
                <Title className={isThreeAnimate ? 'animate__title' : ''}>
                  <p>How to Apply</p>
                </Title>
                <SubTitle style={{marginTop: '2px'}} className={isThreeAnimate ? 'animate__title' : ''}>
                  <p>Moon your project with the</p>
                </SubTitle>
                <SubTitle className={isThreeAnimate ? 'animate__title' : ''}>
                  <p>top-tier team</p>
                </SubTitle>
                <Text className={isThreeAnimate ? 'animate__text' : ''}>
                  <p>As long as you want to build your project with top minds, you can apply via the email below. Frontier Incubate will guide your through from product designing to marketing. We have a large user base which can provide an unique access to your target users. </p>
                </Text>
                <Text className={isThreeAnimate ? 'animate__text' : ''}>
                  <p>Email your application to contact@frontierlabs.tech with your innovations inside, your application will be proceeded faster.</p>
                </Text>
              </AppText>
            </AppItem>
          </HomeAppContent>
        </HomeApp>
      </Hidden>
    </>
  );
};

export default memo(Home);
