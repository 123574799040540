import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 46 62" fill="none" {...props}>
    <path d="M0.612415 39.0845L45.9178 61.9685L15.8352 0.818739L0.612415 39.0845Z" fill="#003AFE"/>
  </Svg>
  
);

export default Icon;
