import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 68 68" fill="none"  {...props}>
    <path d="M44.3951 17.7999L44.6607 17.6861L44.8651 17.4818L50.8545 11.4924L56.9854 17.6233L57.1073 17.7637C58.8776 19.803 59.0646 23.7076 57.0253 28.9778C55.0361 34.1188 51.0996 40.0464 45.6098 45.5362C40.12 51.026 34.1924 54.9625 29.0515 56.9517C23.7812 58.991 19.8766 58.804 17.8373 57.0337L16.7818 56.1173L16.7678 56.1313L11.4917 50.8552L17.5554 44.7915L17.7598 44.5871L17.8736 44.3214C19.9712 39.4246 23.7455 33.9164 28.8677 28.7941C33.99 23.6719 39.4974 19.8983 44.3951 17.7999Z" stroke="#050E2D" strokeWidth="3"/>
    <path d="M39.643 39.5687C51.078 28.1337 56.1252 14.6411 50.9162 9.43207C45.7072 4.22308 32.2146 9.27026 20.7796 20.7053C9.3446 32.1403 4.29742 45.6329 9.50641 50.8419C14.7154 56.0508 28.208 51.0037 39.643 39.5687Z" fill="#4C79F4"/>
  </Svg>
  
);

export default Icon;
