import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 262 159" fill="none" {...props}>
    <path d="M258.427 7.76784C250.131 -0.315228 236.831 -0.136737 228.739 8.15032L181.107 57.0057H73.9203C34.4051 57.0057 2.36914 89.0065 2.36914 128.478V159H86.607V115.653H156.933C169.364 115.653 181.311 110.374 189.633 101.195L199.69 90.154H211.483C213.015 90.154 214.24 88.93 214.24 87.4001C214.24 83.5498 213.5 79.8525 212.173 76.4612L255.287 29.1612C256.844 27.4528 256.716 24.7754 255.006 23.22C254.036 22.3276 252.99 21.5626 251.917 20.8996L258.478 14.168C260.265 12.3831 260.214 9.52725 258.427 7.76784Z" fill="white"/>
    <path d="M204.004 87.9611L189.607 103.745C181.259 112.924 169.339 118.202 156.907 118.202H86.6067" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M2.36914 159V125.928C2.36914 86.456 34.4051 54.4552 73.9203 54.4552H183.787C200.583 54.4552 214.215 68.0715 214.215 84.8496C214.215 86.3795 212.989 87.6034 211.458 87.6034H146.773" fill="white"/>
    <path d="M2.36914 159V125.928C2.36914 86.456 34.4051 54.4552 73.9203 54.4552H183.787C200.583 54.4552 214.215 68.0715 214.215 84.8496C214.215 86.3795 212.989 87.6034 211.458 87.6034H146.773" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M86.6067 115.653V159" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M183.25 54.7107L228.739 8.1502C236.831 -0.13686 250.105 -0.31535 258.426 7.76772C260.213 9.52713 260.264 12.383 258.503 14.1679L249.39 23.4494" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M197.98 56.7248L224.91 27.1974C232.849 18.5023 246.327 17.8649 255.032 25.7949C256.742 27.3504 256.87 30.0277 255.313 31.7361L213.781 77.2512" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
  </Svg>
  
);

export default Icon;