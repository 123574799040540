import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 135 136" fill="none" {...props}>
    <path d="M38.9011 26.6443L47.7114 21.722C71.6129 8.36829 101.813 16.9185 115.166 40.8199C128.52 64.7213 119.97 94.9212 96.0684 108.275L87.258 113.197C63.3566 126.551 33.1568 118.001 19.8031 94.0993C6.4494 70.1979 14.9996 39.998 38.9011 26.6443Z" fill="white" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M107.472 95.4125C93.475 119.926 62.2204 128.338 37.7788 114.353C13.3371 100.368 4.85384 69.101 18.8315 44.6574C32.8358 20.1464 64.0141 11.7128 88.5251 25.717C113.016 39.7907 121.45 70.9689 107.472 95.4125Z" fill="#003AFE"/>
    <path d="M86.5136 68.7569C89.3066 62.4905 85.3561 57.6037 79.2107 53.2717L83.8961 45.0314L78.9117 42.1293L74.2872 50.1547C72.9397 49.3836 71.5657 48.6799 70.2876 47.9285L74.9337 39.8269L69.9493 36.9248L65.2639 45.1652C64.1881 44.5459 63.0837 44.001 62.0773 43.4014L55.2041 39.4177L52.1306 44.7432C52.1306 44.7432 55.8585 46.8175 55.7822 46.7959C57.7951 47.9951 57.8412 49.499 57.437 50.6357L52.0414 60.0085C52.1674 60.1191 52.3824 60.1799 52.5828 60.3191C52.4587 60.2015 52.2368 60.1387 52.0414 60.0085L44.4736 73.1639C43.9977 73.7334 43.0571 74.5683 41.6353 73.7687C41.69 73.8666 37.9837 71.716 37.9837 71.716L33.545 77.1269L40.0988 80.8629C41.3056 81.6016 42.529 82.2551 43.6595 82.9723L38.8761 91.2673L43.8606 94.1693L48.546 85.929C49.8788 86.7783 51.2262 87.5494 52.524 88.2314L47.8582 96.4024L52.8426 99.3045L57.6259 91.0094C66.3576 95.362 73.4648 96.5876 78.7135 89.136C82.93 83.1078 81.5487 78.5663 77.4768 74.664C81.1558 74.9265 84.4284 73.3433 86.5136 68.7569ZM69.2745 82.5455C65.712 88.7513 55.4823 81.9369 52.026 80.0144L58.3534 69.0175C61.8097 70.94 72.9889 76.0679 69.2745 82.5455ZM75.8591 65.6655C72.6072 71.33 64.1207 65.556 61.2731 63.9707L67.0009 53.9802C69.8484 55.5656 79.2648 59.7224 75.8591 65.6655Z" fill="white"/>
  </Svg>
  
);

export default Icon;
