import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 126 126" fill="none"  {...props}>
    <path d="M29.0645 26.472L37.1802 20.473C59.197 4.19852 90.2368 8.85336 106.511 30.8702C122.786 52.8869 118.131 83.9268 96.1141 100.201L87.9984 106.2C65.9817 122.475 34.9418 117.82 18.6673 95.8031C2.39285 73.7863 7.04769 42.7465 29.0645 26.472Z" fill="white" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M59.9338 110.207C87.8278 114.39 113.831 95.1686 118.014 67.2747C122.197 39.3808 102.976 13.3773 75.0821 9.19418C47.1882 5.01111 21.1846 24.2325 17.0016 52.1265C12.8185 80.0204 32.0399 106.024 59.9338 110.207Z" fill="#003AFE"/>
    <path d="M64.8365 77.5151L62.1981 95.1086L88.4072 67.9517L64.8365 77.5151Z" fill="#CDCFD5"/>
    <path d="M68.9072 50.369L65.512 73.0096L89.0827 63.4461L68.9072 50.369Z" fill="#9B9FAB"/>
    <path d="M72.8167 24.2997L68.9061 50.3763L89.0816 63.4534L72.8167 24.2997Z" fill="#CDCFD5"/>
    <path d="M64.8363 77.5148L62.1979 95.1083L45.1077 61.4579L64.8363 77.5148Z" fill="white"/>
    <path d="M68.9073 50.3687L65.512 73.0092L45.7823 56.9595L68.9073 50.3687Z" fill="#CDCFD5"/>
    <path d="M72.8167 24.3003L68.9061 50.3769L45.7823 56.9606L72.8167 24.3003Z" fill="white"/>
  </Svg>
  
);

export default Icon;
