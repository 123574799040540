/**
 * Create by Krysal
 * Description: footer
 * Date: 2023/1
 * Update: 2023/1
 */
import React from 'react';
import { useEffect, useState } from "react";
import styled from "styled-components";
import Hidden from "./Hidden";
import "../pages/Home/index.css"
import "./Header.css"
import { DialogLeftIcon, DialogRightIcon, WhiteEnvIcon } from "./Svg";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FooterContainer = styled.div`
  margin-top: 160px;
  background: linear-gradient(274.1deg, #003AFE 22.73%, #5684FD 100%);
  height: 514px;
  padding: 70px 16px 0;
`
const FooterContent = styled(Flex)`
  max-width: 1240px;
  margin: auto;
`

const Left = styled.div`
  width: 610px;
  text-align: left;
  position: relative;
`

const Right = styled.div`
  position: relative;
  text-align: right;
`

const Title = styled.div`
  overflow: hidden;
  p {
    -webkit-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)
    font-family: 'Outfit-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 120%;
    color: #fff;
  }
  @media (max-width: 852px) {
    max-width: 278px;
    p{
      color: #fff;
      font-size: 24px;
      text-align: left;
    }
  }
`

const Text = styled.div`
  width: 460px;
  padding-top: 16px;
  overflow: hidden;
  p {
    -webkit-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)
    font-family: 'Outfit-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #fff;
  }
  @media (max-width: 852px) {
    width: 100%;
    padding-top: 24px;
    p {
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
    }
  }
`

const MenuButton = styled.div`
  position: relative;
  width: 148px;
  height: 48px;
  border: 1px solid #fff;
  border-radius: 16px;
  cursor: pointer;
  margin-top: 60px;
  line-height: 48px;
  overflow: hidden;
  a {
    display: block;
    font-family: Montserrat-Bold;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    text-align: center;
    
    &:hover {
      color: #003AFE;
    }
    z-index: 10;
    position: inherit;
  }
  @media (max-width: 852px) {
    width: 156px;
    height: 42px;
    line-height: 42px;
    cursor: unset;
    margin: 60px auto 0;
  }
`

const WhiteEnv = styled(WhiteEnvIcon)`
  margin-right: 140px;
  position: relative;
  top: -80px;
  @media (max-width: 852px) {
    margin-right: unset;
    top: unset;
    right: -20px;
    padding: 40px 0 50px;
  }
`

const DialogLeft = styled(DialogLeftIcon)`
  position: absolute;
  left: 120px;
  top: -66px;
  @media (max-width: 852px) {
    right: 127px;
    left: unset;
    top: 42px;
  }
`

const DialogRight = styled(DialogRightIcon)`
  position: absolute;
  right: 160px;
  bottom: 62px;
  @media (max-width: 852px) {
    right: 29px;
    bottom: 30px;
  }
`

const Contact = styled.div`
  position: absolute;
  bottom: -80px;
  left: 80px;
  a {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    // text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #FFFFFF;
    display: block;
  }
`

const AppFooter = styled.div`
  padding: 0 14px;
  box-sizing: border-box;
  margin-top: 25px;
`

const AppFooterContainer = styled.div`
  width: 100%;
  background: linear-gradient(221.05deg, #033DFE 5.83%, #4E7DFD 100%);
  box-shadow: 4px 4px 24px rgba(210, 212, 217, 0.4);
  border-radius: 20px;
  // padding-top: 40px;
  padding: 40px 24px 24px;
  box-sizing: border-box;
`

const AppImage = styled.div`
  position: relative;
`

const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all .4s;
  transform: scale(0)
`


const Footer = () => {
  const [isAnimate, setIsAnimate] = useState(false)
  const [isCircle, setIsCircle] = useState(false)
  const scrollChange = () => {
    // scrollTop
    if (document.documentElement.clientWidth >= 825 && document.documentElement.scrollTop > 1420) {
      setIsAnimate(true)
    } else if (document.documentElement.clientWidth < 825 && document.documentElement.scrollTop > 820) {
      setIsAnimate(true)
    } else {
      setIsAnimate(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollChange,true)
    scrollChange()
    return () => {
        window.removeEventListener('scroll', scrollChange,false)
    }
  }, [])



    return (
      <>
        <Hidden middleDown>
          <FooterContainer id="about">
            <FooterContent>
              <Left>
                <Title className={isAnimate ? 'animate__title' : ''}>
                  <p>About Frontier Labs</p>
                </Title>
                <Text className={isAnimate ? 'animate__text' : ''}>
                  <p>Frontier Labs is a hub of global blockchain builders. We’ve incubated top-notch projects that have excellent performances. The Frontier Labs team consists of seasoned professionals with expertise in product R&D, marketing, community building and fundraising. We provide top-notch support, advisory, operational guidance and access to industry experts to help builders and crypto startups scale their projects.</p>
                </Text>
                <MenuButton onMouseEnter={() => {
                  setIsCircle(true)
                }}  onMouseLeave={() => {
                  setIsCircle(false)
                }}>
                  <Circle className={`${isCircle ? 'is-circle' : ''} 'circle'`}></Circle>
                  <a href="mailto:contact@frontierlabs.tech" target='_blank' rel='noreferrer'>Contact Us</a>
                </MenuButton>
              </Left>
              <Right>
                <WhiteEnv className={isAnimate ? 'animate_handup': ''} width={340} />
                <DialogLeft className={isAnimate ? 'animate_commonTwo': ''} width={60} />
                <DialogRight className={isAnimate ? 'animate_common': ''} width={60} />
                <Contact>
                  <a href="mailto:contact@frontierlabs.tech" target='_blank' rel='noreferrer'>contact@frontierlabs.tech</a>
                </Contact>
              </Right>
            </FooterContent>
          </FooterContainer>
        </Hidden>
        <Hidden smallUp>
          <AppFooter>
            <AppFooterContainer>
              <Title className={isAnimate ? 'animate__title' : ''}>
                <p>About Frontier Labs</p>
              </Title>
              <AppImage>
                <WhiteEnv className={isAnimate ? 'animate_handup': ''} width={240} />
                <DialogLeft className={isAnimate ? 'animate_commonTwo': ''} width={60} />
                <DialogRight className={isAnimate ? 'animate_common': ''} width={60} />
              </AppImage>
              <Text className={isAnimate ? 'animate__text' : ''}>
                <p>Frontier Labs is a hub of global blockchain builders. We’ve incubated top-notch projects that have excellent performances. The Frontier Labs team consists of seasoned professionals with expertise in product R&D, marketing, community building and fundraising. We provide top-notch support, advisory, operational guidance and access to industry experts to help builders and crypto startups scale their projects.</p>
              </Text>
              <MenuButton>
                <a href="mailto:contact@frontierlabs.tech" target='_blank' rel='noreferrer'>Contact Us</a>
              </MenuButton>
            </AppFooterContainer>
          </AppFooter>
        </Hidden>
      </>
    );
  };
  
  export default Footer;
  