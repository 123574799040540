/**
 * Create by Krystal
 * Description: config
 * Date: 2022/11
 * Update: 2022/11
 */
import React, { ReactNode } from "react";
import Home from "../pages/Home";

export interface RouterConfig {
  path?: string;
  element?: ReactNode;
  children?: RouterConfig[];
  name?: string;
  url?: string;
  key: string;
}

export type RouterConfigType = RouterConfig[];

export const useRouterConfig = () => {
  const router = [
    {
      path: "/",
      element: <Home />,
      key: "home",
    },
  ];
  return router;
};
