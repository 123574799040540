import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 375 187" fill="none" {...props}>
  <rect x="258.182" width="8.86552" height="8.86552" transform="rotate(45 258.182 0)" fill="white"/>
  <rect x="239.269" y="12.4121" width="8.86552" height="8.86552" transform="rotate(45 239.269 12.4121)" fill="white"/>
  <rect x="264.092" y="23.4092" width="6.94027" height="6.94027" transform="rotate(45 264.092 23.4092)" fill="white"/>
  <path d="M306.371 33.2276L115.752 93.0098L144.413 184.397L335.032 124.614L306.371 33.2276Z" fill="white"/>
  <path d="M130.956 114.051L228.22 122.263L304.678 55.2659" stroke="#003AFE" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="bevel"/>
  <path d="M284.806 113.928C295.815 110.439 297.707 103.645 297.707 103.645C297.707 103.645 300.976 95.0606 296.001 95.1059C291.694 95.1446 290.548 105.08 291.236 110.168C291.701 115.321 294.973 123.998 299.02 121.649C303.066 119.299 298.759 112.696 298.759 112.696C302.058 112.506 304.986 110.063 306.227 106.936" stroke="#003AFE" strokeWidth="3.50037" strokeMiterlimit="10"/>
  <path d="M73.5808 84.5566L27.4227 84.5566" stroke="white" strokeWidth="3"/>
  <path d="M45.4192 131.714H78.2427" stroke="white" strokeWidth="3"/>
  <path d="M0 144.68H67.6985" stroke="white" strokeWidth="3"/>
  <path d="M107.581 71L17.9999 71" stroke="#0BDB90" strokeWidth="3"/>
  <path d="M21.4192 160.005L111 160.005" stroke="#0BDB90" strokeWidth="3"/>
  <rect x="348.465" y="149" width="7.72852" height="7.72852" transform="rotate(45 348.465 149)" fill="white"/>
  <rect x="364.49" y="155.352" width="14.5683" height="14.5683" transform="rotate(45 364.49 155.352)" fill="white"/>
  </Svg>
);

export default Icon;