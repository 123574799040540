/**
 * Create by Krysal
 * Description: header
 * Date: 2023/1
 * Update: 2023/1
 */
import React from 'react';
import { useState } from 'react'
import styled from 'styled-components'
import './Header.css'

const HeaderContainer = styled.div`
  display: flex;
  padding: 0 10px;
  margin: auto;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  min-width: 1240px;
  height: 96px;
  @media (min-width: 852px) and (max-width: 1100px) {
    min-width: unset;
  }
  @media (max-width: 852px) {
    max-width: unset;
    min-width: unset;
    width: 100%;
    padding: 0;
    align-items: unset;
  }
`

const LogoContainer = styled.div`
  height: 48px;
  width: auto;
  img {
    display: block;
    height: 100%;
    width: auto;
  }
  @media (max-width: 852px) {
    width: 100%;
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    img {
      display: block;
      height: 32px;
      width: auto;
      margin: auto;
    }
  }
`

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 140px;
  @media (min-width: 852px) and (max-width: 1100px) {
    margin-right: 10px;
  }
  @media (max-width: 852px) {
    display: none;
  }
`

const MenuA = styled.a`
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #050E2D;
  margin-right: 74px;
  font-family: Montserrat;
  &:after {
    content: "";
    width: 1px;
    display: block;
    height: 2px;
    background-color: transparent;
    transition: width .2s;
  }
  &:hover {
    color: #003AFE;
    &:after {
      width: 100%;
      background-color: #003AFE;
    }
  }
`

const MenuButton = styled.div`
  width: 148px;
  height: 48px;
  color: #050E2D;
  text-align: center;
  line-height: 48px;
  transition-duration: .5s;
  transition: all .6s;
  -webkit-transition: all .6s; /* Safari */
  border: 1px solid #050E2D;
  font-family: Montserrat-Bold;
  font-weight: 700;
  font-size: 16px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  &:hover {
    color: #fff;
  }
  a {
    z-index: 10;
    position: inherit;
    display: block;
  }
  overflow: hidden;
`
const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #003AFE;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all .4s;
  transform: scale(0)
`


const Header = () => {
  const [isCircle, setIsCircle] = useState(false)

  const scrollToAnchor = (anchorName: string) => {
    if (anchorName) {
        let anchorElement = document.getElementById(anchorName);
        if (anchorElement) {
          anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
        }
    }
  }

  return (
    <HeaderContainer>
      <LogoContainer>
        <img src={require("../assets/images/Frontier Logo.png")} alt="" />
      </LogoContainer>
      <MenuContainer>
        {/* <MenuA>Outfit</MenuA> */}
        <MenuA onClick={() => scrollToAnchor('incubate')}>Incubate</MenuA>
        <MenuA onClick={() => scrollToAnchor('about')}>About Us</MenuA>
        <MenuButton onMouseEnter={() => {
          setIsCircle(true)
        }}  onMouseLeave={() => {
          setIsCircle(false)
        }}>
          <Circle className={`${isCircle ? 'is-circle' : ''} 'circle'`}></Circle>
          <a href="mailto:contact@frontierlabs.tech" target='_blank' rel='noreferrer'>Contact Us</a>
        </MenuButton>
      </MenuContainer>
    </HeaderContainer>
  );
};

export default Header;
