import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 213 247" fill="none" {...props}>
  <path d="M212.828 159.035H148.841V107.046L160.965 92.4943C164.482 88.2649 166.534 82.9257 166.262 77.4399C165.948 71.4726 163.205 66.0496 158.619 62.2808L133.201 41.3427C131.212 39.7096 128.259 39.9818 126.626 41.9709L125.202 43.6878C125.035 43.8762 124.909 44.0856 124.742 44.295V19.1065C124.742 16.594 122.522 14.563 119.8 14.563C111.718 14.563 104.767 18.96 101.584 25.3042V7.3603C101.584 4.42898 99.2183 2.0211 96.3079 2.00017C86.5508 1.95829 78.6152 9.87287 78.6152 19.6091V29.2196C78.6152 25.7439 75.8096 22.9382 72.3338 22.9382H72.0616C62.9955 22.9382 55.6462 30.2874 55.6462 39.3536V148.692L38.833 112.469C32.6144 99.0898 16.7434 93.269 3.36397 99.4667C2.21237 99.9902 1.66799 101.351 2.21237 102.524L44.7166 194.065C58.9544 224.718 89.6915 244.337 123.485 244.337H212.807V159.035H212.828ZM127.694 69.3369L139.482 79.0312L124.763 96.6819V66.259C125.6 67.3687 126.584 68.4156 127.694 69.3369Z" fill="white"/>
  <path d="M124.511 65.7981C125.433 67.0963 126.521 68.2897 127.82 69.3576L139.608 79.0519L123.841 97.9589" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
  <path d="M148.967 159.035V107.046L161.09 92.494C164.608 88.2645 166.66 82.9253 166.387 77.4395C166.073 71.4722 163.33 66.0493 158.745 62.2804L133.326 41.3424C131.337 39.7092 128.385 39.9814 126.752 41.9705L125.328 43.6874" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
  <path d="M124.762 96.2209V19.1061C124.762 16.5936 122.543 14.5626 119.821 14.5626C108.745 14.5626 99.7625 22.8121 99.7625 32.988V104.596" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
  <path d="M99.7623 83.6583V6.54355C99.7623 4.03099 97.7313 2 95.2188 2C85.001 2 76.7305 10.2496 76.7305 20.4255V92.0335" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
  <path d="M212.828 244.881H123.506C89.6914 244.881 58.9543 225.262 44.7374 194.608L2.21228 103.067C1.68883 101.916 2.1704 100.555 3.32199 100.01C16.7223 93.7919 32.6143 99.6127 38.8329 113.013L55.7927 148.566V40.9443C55.7927 30.9987 63.2676 22.9376 72.5012 22.9376C74.7625 22.9376 76.6051 24.9267 76.6051 27.3765V102.711" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="bevel"/>
  <path d="M137.451 159.035H212.828" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
  </Svg>
  
);

export default Icon;
