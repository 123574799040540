import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 61 57" fill="none" {...props}>
    <rect x="42.6067" width="15" height="15" transform="rotate(45 42.6067 0)" fill="#003AFE"/>
    <rect x="10.6067" y="21" width="15" height="15" transform="rotate(45 10.6067 21)" fill="#003AFE"/>
    <rect x="52.6069" y="39.6064" width="11.7426" height="11.7426" transform="rotate(45 52.6069 39.6064)" fill="#003AFE"/>
  </Svg>
);

export default Icon;