import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 46 62" fill="none"  {...props}>
    <path d="M45.3053 39.0845L-4.19617e-05 61.9685L30.0826 0.818739L45.3053 39.0845Z" fill="#0BDB90"/>
  </Svg>
  
);

export default Icon;
