import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 768 533" fill="none" {...props}>
    <path d="M484 473.406L180 531V120.587L484 63V473.406Z" fill="white" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M505 441.406L201 499V88.5868L505 31V441.406Z" fill="#003AFE"/>
    <path d="M527 412.406L223 470V59.5868L527 2V412.406Z" fill="white" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M5 338.138C43.6987 382.232 152.262 445.76 276.926 347.115" stroke="#003AFE" strokeWidth="12"/>
    <path d="M290.049 128.97C273.992 121.254 252.922 131.761 242.988 152.437C233.053 173.113 238.017 196.128 254.074 203.843C270.131 211.558 291.202 201.052 301.136 180.376C311.07 159.7 306.107 136.685 290.049 128.97Z" fill="#0BDB90"/>
    <path d="M467.022 363.726L285.283 398.123V153.014L467.022 118.621V363.726Z" fill="white" stroke="#003AFE" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M292.803 157.445L279.016 159.982V146.225L292.803 143.689V157.445Z" fill="white" stroke="#003AFE" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M292.803 405.612L279.016 408.149V394.392L292.803 391.855V405.612Z" fill="white" stroke="#003AFE" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M473.288 123.604L459.501 126.141V112.385L473.288 109.848V123.604Z" fill="white" stroke="#003AFE" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M223 92L527 34" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M261.067 65.2401C263.448 67.3519 263.502 71.6166 260.659 74.8208C257.817 78.0251 253.576 78.4804 251.195 76.3686C248.814 74.2567 248.76 69.9921 251.603 66.7878C254.445 63.5835 258.686 63.1283 261.067 65.2401Z" stroke="#050E2D" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M287.988 70.6875C291.08 67.2013 291.225 62.2799 288.311 59.6951C285.397 57.1104 280.527 57.8411 277.435 61.3273C274.342 64.8135 274.197 69.735 277.111 72.3198C280.025 74.9045 284.895 74.1737 287.988 70.6875Z" fill="#003AFE"/>
    <path d="M313.866 66.0205C316.959 62.5343 317.104 57.6129 314.19 55.0281C311.275 52.4434 306.406 53.1741 303.313 56.6603C300.221 60.1465 300.076 65.068 302.99 67.6528C305.904 70.2375 310.774 69.5067 313.866 66.0205Z" fill="#0BDB90"/>
    <path d="M763.403 162.401C736.585 141.79 667.806 117.878 607.233 187.119" stroke="#003AFE" strokeWidth="12"/>
    <path d="M527 186.941C568.516 186.817 582.316 165.544 582.316 165.544C582.316 165.544 602.764 139.616 585.649 134.419C570.834 129.918 556.216 162.766 553.099 180.961C549.152 199.138 551.042 232.42 567.45 228.715C583.859 225.01 576.187 197.726 576.187 197.726C587.707 200.623 600.378 195.393 608 186" stroke="#003AFE" strokeWidth="12" strokeMiterlimit="10"/>
    <path d="M96.6124 223.084L141.918 245.968L111.835 184.819L96.6124 223.084Z" fill="#FDC827"/>
    <path d="M731.3 441.948L680.701 445.949L738.186 482.551L731.3 441.948Z" fill="#FDC827"/>
    <path d="M700.305 58.0845L655 80.9685L685.083 19.8187L700.305 58.0845Z" fill="#FDC827"/>
  </Svg>
  
);

export default Icon;
