import { useMemo } from "react";
import { useResponsive } from "ahooks";
import { isMobile } from "react-device-detect";

const useSystem = () => {
  const responsive = useResponsive();
  // is small
  const isSmallScreen = useMemo(() => {
    return !responsive.middle || isMobile;
  }, [responsive]);

  // is mobile
  const isApp = useMemo(() => {
    return isMobile;
  }, []);

  return { isSmallScreen, isApp };
};

export default useSystem;
