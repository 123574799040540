import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 78 38" fill="none" {...props}>
    <rect width="78" height="29" rx="14.5" transform="matrix(-1 0 0 1 78 0)" fill="#0BDB90"/>
    <path d="M56 37.5V29H47.5L56 37.5Z" fill="#0BDB90"/>
    <circle r="3.5" transform="matrix(-1 0 0 1 54.5 14.5)" fill="white"/>
    <circle r="3.5" transform="matrix(-1 0 0 1 39.5 14.5)" fill="white"/>
    <circle r="3.5" transform="matrix(-1 0 0 1 24.5 14.5)" fill="white"/>
  </Svg>
  
);

export default Icon;
