import './App.css';
import Router from './router';
import Header from './components/Header';
import Footer from './components/Footer';
import './assets/css/reset.css'
import {configResponsive} from "ahooks";

configResponsive({
  small: 576,
  middle: 852,
  big: 992,
});

function App() {
  return (
    <div className="App">
     <Header/>
     <Router />
     <Footer />
    </div>
  );
}

export default App;
