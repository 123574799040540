import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 373 345" fill="none" {...props}>
    <path d="M315.607 95.6304L310.8 3.30823L286.516 4.5727L277.546 112.879L232.701 194.395C227.896 203.118 230.462 214.063 238.648 219.748L239.836 220.587C242.261 222.263 245.59 221.675 247.266 219.251L285.392 178.454C306.288 156.086 317.198 126.178 315.607 95.6304Z" fill="white"/>
    <path d="M263.754 137.945L232.701 194.395C227.896 203.118 230.462 214.063 238.648 219.748L239.836 220.587C242.261 222.263 245.59 221.675 247.266 219.251L285.392 178.454C306.288 156.086 317.199 126.202 315.607 95.6304L310.8 3.30823" stroke="black" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="bevel"/>
    <path d="M363.386 155.572L8.83911 174.033L17.6899 344.011L372.237 325.549L363.386 155.572Z" fill="#003AFE"/>
    <path d="M25.6855 216.983L189.447 274.197L350.712 192.754" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="bevel"/>
    <path d="M148.45 250.035C146.096 249.257 144.821 246.742 145.599 244.388L152.027 224.746C147.052 231.14 138.49 234.118 130.321 231.549C128.018 230.817 126.722 228.376 127.454 226.074L137.227 194.91C132.699 202.402 123.516 206.094 114.825 203.308L114.379 203.161C112.002 202.408 110.675 199.848 111.452 197.47L136.184 120.166C139.18 110.831 145.588 102.949 154.126 98.0977L228.321 56.0318L225.806 7.73384L296.23 4.06687L302.425 123.051L282.925 124.067L286.18 129.984L221.787 165.307L204.387 220.217C200.958 231.06 189.365 237.094 178.496 233.642C176.838 233.12 175.83 231.541 175.937 229.855L173.478 237.337C170.076 247.764 158.855 253.462 148.45 250.035Z" fill="white"/>
    <path d="M239.582 49.6994L154.128 98.1464C145.589 102.974 139.182 110.855 136.187 120.215L111.454 197.494C110.701 199.871 112.003 202.433 114.38 203.185L114.826 203.333C123.096 205.97 131.817 202.764 136.554 196.017" stroke="black" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="bevel"/>
    <path d="M161.016 119.141L127.454 226.074C126.722 228.376 128.019 230.841 130.32 231.549C137.722 233.866 146.85 231.005 151.938 225.846" stroke="black" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="bevel"/>
    <path d="M201.539 148.685L176.089 229.021C175.484 230.976 176.541 233.039 178.498 233.667C189.341 237.096 200.936 231.087 204.389 220.242L221.789 165.332L274.699 135.842" stroke="black" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="bevel"/>
    <path d="M180.622 136.991L144.822 246.304C144.044 248.658 145.343 251.171 147.673 251.95C158.077 255.353 169.299 249.68 172.702 239.277L175.895 229.517" stroke="black" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="bevel"/>
    <path d="M225.806 7.73389L228.323 56.0804" stroke="black" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="bevel"/>
    <path d="M290.528 284.914C311.02 283.786 317.286 272.929 317.286 272.929C317.286 272.929 326.714 259.603 318.132 257.478C310.702 255.637 304.33 272.228 303.259 281.291C301.778 290.366 303.566 306.748 311.571 304.497C319.576 302.246 315.088 288.974 315.088 288.974C320.849 290.108 326.97 287.2 330.491 282.368" stroke="white" strokeWidth="6.22069" strokeMiterlimit="10"/>
  </Svg>
  
);

export default Icon;
