/**
 * Create by Krystal
 * Description: index
 * Date: 2023/01
 * Update: 2023/01
 */
import { useRoutes } from "react-router-dom";

import { useRouterConfig } from "./config";

const Router = () => {
  const routerConfig = useRouterConfig();
  return useRoutes(routerConfig);
};

export default Router;
