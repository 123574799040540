import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 312 312" fill="none" {...props}>
    <circle cx="156" cy="156" r="156" fill="#FDC827"/>
    <rect x="187.607" y="63" width="15" height="15" transform="rotate(45 187.607 63)" fill="white"/>
    <rect x="155.607" y="84" width="15" height="15" transform="rotate(45 155.607 84)" fill="white"/>
    <rect x="197.607" y="102.606" width="11.7426" height="11.7426" transform="rotate(45 197.607 102.606)" fill="white"/>
    <rect x="55.3032" y="177" width="11.7426" height="11.7426" transform="rotate(45 55.3032 177)" fill="white"/>
    <rect x="79.6516" y="186.651" width="22.1348" height="22.1348" transform="rotate(45 79.6516 186.651)" fill="white"/>
  </Svg>
  
);

export default Icon;
