import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 84 84" fill="none"  {...props}>
    <path d="M68.2736 65.1954L62.8704 70.063C48.3843 83.1133 26.0626 81.9493 13.0123 67.4632C-0.0379713 52.977 1.12598 30.6554 15.6121 17.6051L21.0153 12.7375C35.5015 -0.312809 57.8232 0.851143 70.8734 15.3373C83.9237 29.8234 82.7597 52.1451 68.2736 65.1954Z" fill="white" stroke="#050E2D" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M40.5846 7.5984C20.2871 6.53999 2.97472 22.1364 1.91632 42.4339C0.857916 62.7314 16.4543 80.0437 36.7518 81.1021C57.0493 82.1605 74.3617 66.5642 75.4201 46.2667C76.4785 25.9692 60.8821 8.6568 40.5846 7.5984Z" fill="#003AFE"/>
    <path d="M39.089 18.0964C24.2363 17.322 11.5679 28.7346 10.7934 43.5873C10.0189 58.44 21.4316 71.1083 36.2843 71.8828C51.137 72.6573 63.8053 61.2447 64.5798 46.392C65.3543 31.5393 53.9417 18.8709 39.089 18.0964Z" fill="#0BDB90"/>
  </Svg>
  
);

export default Icon;
