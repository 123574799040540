/**
 * Create by Krystal
 * Description: index
 * Date: 2022/11
 * Update: 2022/11
 */

import React, { ReactNode } from "react";
import { useResponsive } from "ahooks";
import useSystem from "../../hooks/useSystem";

interface HiddenProps {
  children: ReactNode;
  // daping yixia yincang
  bigDown?: boolean;
  // zhongping yishang yincang
  middleUp?: boolean;
  // zhongping yixia yincang
  middleDown?: boolean;
  // xiaoping yishang yincang
  smallUp?: boolean;
  // only yincang
  only?: "small" | "middle" | "big";
  // app
  app?: boolean;
  // not app
  noApp?: boolean;
}

const Hidden = (props: HiddenProps) => {
  const responsive = useResponsive();
  const { isApp } = useSystem();
  if (props.bigDown && !responsive.big) return null;
  if (props.middleUp && responsive.big) return null;
  if (props.middleDown && !responsive.middle) return null;
  if (props.smallUp && (responsive.middle || responsive.big)) return null;
  if (props.only === "small" && (responsive.middle || responsive.big))
    return null;
  if (props.only === "middle" && (responsive.big || !responsive.middle))
    return null;
  if (props.only === "big" && !responsive.big) return null;

  if (props.app && !isApp) return null;

  if (props.noApp && isApp) return null;

  return <>{props.children}</>;
};

export default Hidden;
