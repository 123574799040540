import React from "react";
import Svg, { SvgProps } from "../Svg";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 78 38" fill="none" {...props}>
    <rect width="78" height="29" rx="14.5" fill="#0BDB90"/>
    <path d="M22 37.5V29H30.5L22 37.5Z" fill="#0BDB90"/>
    <circle cx="24.5" cy="14.5" r="3.5" fill="white"/>
    <circle cx="39.5" cy="14.5" r="3.5" fill="white"/>
    <circle cx="54.5" cy="14.5" r="3.5" fill="white"/>
  </Svg>
  
);

export default Icon;
